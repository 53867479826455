import gsap from 'gsap'
import Lenis from 'lenis'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export let lenis, scrollToStart

export const initLenis = () => {

  lenis = new Lenis({
    lerp: 0.1,
    smooth: false,
    direction: 'vertical'
  })

	function raf(time) {
		lenis.raf(time)
		requestAnimationFrame(raf)
	}

	scrollTo()
	requestAnimationFrame(raf)

	lenis.on('scroll', ScrollTrigger.update)

	gsap.ticker.add((time)=>{
		lenis.raf(time * 1000)
	})
	
	gsap.ticker.lagSmoothing(0)

}

const parallaxMouse = (event) => {
  document.querySelectorAll("[data-parallax]").forEach((shift) => {
    const position = shift.getAttribute("data-parallax")
    const x = (window.innerWidth - event.pageX * position) / 90
    const y = (window.innerHeight - event.pageY * position) / 90

    gsap.to(shift, { duration: 1.5, x: x, y: y})
  })
}

document.addEventListener('mousemove', parallaxMouse)

export const scrollTo = () => {
	const links = document.querySelectorAll('[data-scroll-to]')

	if (!links) return

	links.forEach((link) => {
		link.addEventListener('click', (e) => {
			e.preventDefault()
			const target = link.getAttribute('data-scroll-to')
			const offset = link.getAttribute('data-scroll-offset')
			const targetEl = document.querySelector(target)
			lenis.scrollTo(targetEl, {
				duration: 1.5,
				offset: offset ? Number(offset) : 0,
				easing: (x) => {
					return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2
				}
			})
		})
	})
}

scrollToStart = () => {
  lenis.scrollTo(0, { immediate: true })
}

export default () => {}

initLenis()