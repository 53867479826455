import SplitType from 'split-type'

export const splitType = () => {

  document.fonts.ready.then(() => {
    const split = new SplitType('[data-split]', { types: 'chars' })
  })

}

export default () => {}