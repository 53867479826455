/**
 * Wraps the elements of an array.
 * @param {Array} arr - the array of elements to be wrapped
 * @param {String} wrapType - the type of the wrap element ('div', 'span' etc)
 * @param {String} wrapClass - the wrap class(es)
 */

 export const wrapLines = (arr, wrapType, wrapClass) => {
  arr.forEach(el => {
    const wrapEl = document.createElement(wrapType)
    wrapEl.classList = wrapClass
    el.parentNode.appendChild(wrapEl)
    wrapEl.appendChild(el)
  })
}

export default () => {}