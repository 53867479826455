import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export let Animations, Features

Features = () => {

	const el = document.querySelectorAll('.features')
	const items = document.querySelectorAll('.features__item')

	gsap.to(items, {
		scale: 1,
    opacity: 1,
		filter: 'blur(0px)',
		// boxShadow: '0px 149px 42px 0px rgba(0, 0, 0, 0.00), 0px 96px 38px 0px rgba(0, 0, 0, 0.01), 0px 54px 32px 0px rgba(0, 0, 0, 0.03), 0px 24px 24px 0px rgba(0, 0, 0, 0.05), 0px 6px 13px 0px rgba(0, 0, 0, 0.06)',
    stagger: 0.1,
    ease: 'none',
    scrollTrigger: {
      trigger: el,
      start: window.innerWidth < 650 ? 'top 85%' : 'top 70%',
      end: window.innerWidth < 650 ? 'bottom bottom' : 'bottom 75%',
      scrub: true
    }
  })
}

Animations = () => {
	Features()
}

export default () => {}

Animations()