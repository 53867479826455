import { Gradient } from './gradient'

export const background = () => {

	// Create your instance
	const gradient = new Gradient()

	// Call `initGradient` with the selector to your canvas
	gradient.initGradient('#gradient-canvas')

}

export default () => {} 

background()