/**
 * Wraps the elements of an array.
 * @param {Array} arr - the array of elements to be wrapped
 * @param {String} wrapType - the type of the wrap element ('div', 'span' etc)
 * @param {String} wrapClass - the wrap class(es)
 */

export const WrapLines = (arr, wrapType, wrapClass) => {
  arr.forEach((el) => {
    const wrapEl = document.createElement(wrapType)
    wrapEl.classList = wrapClass
    el.parentNode.appendChild(wrapEl)
    wrapEl.appendChild(el)
  })
}

export const isSafariBrowser = () => {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
}

export const isFirefoxBrowser = () => {
  return navigator.userAgent.toLowerCase().includes('firefox');
}

export const setHeaderColour = (container) => {
	document.documentElement.setAttribute('data-header-theme', container.dataset.header)
}

export const refreshScripts = (container) => {
	const scripts = container.querySelectorAll('main script')
	scripts.forEach((script) => {
    eval(script.innerHTML) 
  })
}

export const refreshTrustpilotWidget = (container) => {
  const trustpilot = container.querySelector('.trustpilot-widget')
  if (!trustpilot) return
  window.Trustpilot.loadFromElement(trustpilot)
}

export const autoplayVideos = (container) => {
  const videos = container.querySelectorAll('video[autoplay]')
  videos.forEach((video) => video.play())
}

export default () => {}