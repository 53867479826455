export const submitNewsletter = () => {
	const form = document.querySelector('.banner__form')

	if (!form) return

	form.addEventListener('submit', (e) => {
		e.preventDefault()
		const data = new FormData(e.currentTarget)
		createBrevoContact(data)
	})

	async function createBrevoContact(data) {

		const url = 'https://api.brevo.com/v3/contacts'
		const options = {
			method: 'POST',
			headers: {
				'api-key': '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',
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				"email": data.get("email"),
				"listIds": [2]
			})
		}
	 
		try {
			toggleLoading()
			const res = await fetch(url, options)
			const data = await res.json()

			if (data.code == 'duplicate_parameter') {
				showErrorMessage('This email address is already subscribed.')
			} else if (data.code == 'invalid_parameter') {
				showErrorMessage('Sorry, something went wrong. Please try again.')
			} else {
				showSuccessMessage()
			}
		} catch (err) {
			console.log(err)
		} finally {
			toggleLoading()
		}
	}

	const showErrorMessage = (message) => {
		const node = `<p class="error">${ message }</p>`
		form.innerHTML += node
	}

	const showSuccessMessage = () => {
		const node = `<p class="success">Thanks for registering! You will hear more from us soon.</p>`
		form.innerHTML = node
	}

	const toggleLoading = () => {
		const submit = form.querySelector('button')
		submit.classList.toggle('loading')
	}
}

export default () => {}

submitNewsletter()